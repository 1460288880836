const SvgChevronLeftBox = (props) => (
  <svg
    width="3.7rem"
    height="3.7rem"
    viewBox="0 0 50 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M6.25 25c0-14.583 4.169-18.75 18.751-18.75S43.75 10.417 43.75 25s-4.327 18.75-18.749 18.75C10.579 43.75 6.25 39.583 6.25 25Z"
      stroke="#333335"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M27.083 31.25 20.833 25l6.25-6.25"
      stroke="#333335"
      strokeWidth={3}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgChevronLeftBox;
