const SvgActa = (props) => (
  <svg
    width="6rem"
    height="6rem"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m47.258 34.92-4.776-12.047-.138.358-2.35 5.794a20.683 20.683 0 0 0-4.074-1.358V24.61h2.482v-1.447H32.04v1.447h2.379v2.777a24.64 24.64 0 0 0-3.716-.276 27.924 27.924 0 0 0-6.265.686c.451-2.53 2.275-2.94 3.88-2.994a4.565 4.565 0 0 1 2.392.49l.242.15V23.77l-.097-.041a7.734 7.734 0 0 0-2.86-.566c-1.594.141-5.25.92-5.455 5.15-.823.259-1.63.564-2.418.914l-2.507-6.325-.138.358-4.74 11.688h1.73l.836-2.261a18.811 18.811 0 0 1 1.982-1.504c.467-.308.994-.627 1.584-.943l1.793 4.705h1.73l-2.132-5.382c.741-.329 1.5-.618 2.272-.866.004 5.487 4.066 6.168 5.314 6.243a6.366 6.366 0 0 0 2.977-.531l.082-.041v-1.654l-.234.131a5.882 5.882 0 0 1-2.715.544 3.472 3.472 0 0 1-2.55-.972c-.752-.771-1.11-1.977-1.068-3.576.006-.232.021-.45.045-.657a27.509 27.509 0 0 1 6.322-.707c1.244-.003 2.486.09 3.715.278v7.192h1.505V28.04c1.353.306 2.671.75 3.934 1.324l-2.254 5.555h1.728s.967-2.613 1.818-4.907a18.87 18.87 0 0 1 3.422 2.4l.956 2.51 1.728-.003Zm-30.193-4.026c-.519.343-1.021.71-1.505 1.102.814-2.197 1.81-4.884 1.962-5.291l1.217 3.194c-.626.335-1.184.67-1.674.996Zm25.34-.564a19.83 19.83 0 0 0-1.128-.664l1.108-2.989 1.91 5.01a18.781 18.781 0 0 0-1.89-1.357Z"
      fill="#696363"
    />
    <path
      d="M30.89 29.89a.048.048 0 0 0 .062.026l.261-.151-.165.806a.055.055 0 0 0 .09.061l.337-.386.076.186c.027.035.048.035.09.035l.379-.09-.125.462c-.014.027 0 .061.014.061l.166.076-.69.551a.103.103 0 0 0-.048.11l.09.228-.69-.124c-.048 0-.062.027-.062.034l.015.717h-.118l.015-.717c0-.014-.015-.034-.063-.034l-.689.124.09-.228a.108.108 0 0 0-.049-.11l-.689-.551.151-.076c.028 0 .035-.034.028-.061l-.124-.462.366.09a.12.12 0 0 0 .103-.035l.075-.186.339.386a.056.056 0 0 0 .067.002.057.057 0 0 0 .022-.064l-.166-.806.262.152c.028.014.049 0 .062-.027l.262-.517.255.517ZM48 37.214H12l.565-1.393h34.892L48 37.214Z"
      fill="#696363"
    />
  </svg>
);

export default SvgActa;
