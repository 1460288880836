const SvgVisa = (props) => (
  <svg
    width="6rem"
    height="5.478rem"
    viewBox="0 0 60 55"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M25.669 22.314 20.955 32.58H17.88l-2.32-8.193a1.068 1.068 0 0 0-.216-.533 1.212 1.212 0 0 0-.474-.369c-.913-.399-1.877-.692-2.87-.874l.069-.297h4.95c.323 0 .636.105.882.297s.41.458.46.75l1.226 5.94 3.024-6.987h3.058Zm4.157 0L27.421 32.58h-2.91l2.406-10.266h2.91Zm7.89 6.914c.013-2.71-4.102-2.86-4.075-4.07.01-.369.393-.76 1.234-.86a5.954 5.954 0 0 1 2.868.46l.51-2.18a8.55 8.55 0 0 0-2.72-.451c-2.876 0-4.9 1.394-4.916 3.393-.018 1.478 1.445 2.3 2.545 2.795 1.132.503 1.515.826 1.51 1.275-.008.688-.906.994-1.74 1.005a6.55 6.55 0 0 1-2.988-.648l-.527 2.25a9.537 9.537 0 0 0 3.234.545c3.057 0 5.057-1.378 5.066-3.514h-.001Zm7.594 3.352H48l-2.35-10.266h-2.482a1.417 1.417 0 0 0-.745.202 1.24 1.24 0 0 0-.494.55l-4.365 9.514h3.055l.606-1.534h3.734l.35 1.534Zm-3.247-3.637 1.532-3.856.88 3.855h-2.412Z"
      fill="#696363"
    />
  </svg>
);

export default SvgVisa;
