const SvgCall2 = ({color="#626F81", ...props}) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.02 2.093c1.004.383 1.688 1.28 2.013 2.533.085.33.174.666.264 1.005.15.57.303 1.147.445 1.716.171.682.027 1.305-.18 1.803-.188.457-.463.888-.679 1.224l-.035.056c-.247.388-.403.645-.48.862a.525.525 0 0 0-.038.167c0 .011 0 .015.007.027.755 1.403 1.774 2.422 3.177 3.177a.044.044 0 0 0 .027.007c.02 0 .071-.004.167-.038.217-.077.474-.233.862-.48l.056-.035c.336-.216.768-.49 1.224-.68.498-.206 1.12-.35 1.803-.18l2.817.705c1.254.313 2.15.998 2.511 2.019.342.967.113 1.988-.305 2.82-.425.846-1.105 1.629-1.896 2.204-.786.572-1.759.995-2.78.995C8.716 22 2 15.284 2 7c0-1.036.414-2.015.987-2.805.576-.792 1.36-1.461 2.21-1.87.841-.402 1.863-.599 2.824-.232ZM4.607 5.371C4.21 5.916 4 6.492 4 7c0 7.18 5.82 13 13 13 .478 0 1.047-.207 1.604-.612a4.294 4.294 0 0 0 1.285-1.485c.28-.556.302-.987.207-1.255-.076-.213-.305-.544-1.11-.746l-2.818-.704c-.12-.03-.285-.024-.554.088-.286.118-.583.306-.969.553l-.02.012c-.34.218-.786.502-1.25.666-.508.18-1.156.258-1.809-.093-1.748-.94-3.05-2.242-3.99-3.99-.351-.653-.273-1.3-.093-1.81.164-.463.448-.908.666-1.249l.012-.02c.247-.386.435-.683.553-.969.112-.27.118-.433.088-.554-.14-.559-.285-1.109-.433-1.666-.09-.342-.181-.686-.272-1.038-.212-.818-.555-1.077-.79-1.166-.276-.106-.704-.092-1.245.167-.53.254-1.062.699-1.456 1.242Z"
      fill={color}
    />
  </svg>
);

export default SvgCall2;
