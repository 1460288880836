const SvgChevronDownBox = (props) => (
  <svg
    width="3.2rem"
    height="3.2rem"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M28 16c0 9.333-2.668 12-12 12-9.333 0-12-2.667-12-12S6.77 4 16 4s12 2.667 12 12Z"
      fill="#fff"
      fillOpacity={0.2}
    />
    <path
      d="m12 17.333 4-4 4 4"
      stroke="#fff"
      strokeWidth={1.6}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgChevronDownBox;
