import s from './index.module.sass'

// icons
import Full2Logo from '../../../Icons/Logo/Full2'
import ChevronDownBoxIcon from '../../../Icons/icon/ChevronDownBox'

// components
import AgentCard from '../../../AgentCard'
import GlassButton from '../../../UI/Buttons/glass'

export default function CheckoutPcLayout({ children, component, hasItinerary }) {
  return (
    <section className={s.layout}>

      <header className={s['header']}>

        <a href="https://fivestarairways.com/"><img className={s['header-logo']} src="checkout/logo.png" alt="checkout"  /></a>

        <div>
          <span className={s['header__title']}>Review Details & Confirm Your Trip</span>
          {component}
        </div>
        {hasItinerary &&
          <div className={s['header__agent']}><AgentCard /></div>
        }

      </header>

      <main className={s['main']}>
        {children}
      </main>

    </section>
  )
}