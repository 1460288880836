import { useRouter } from 'next/router'
import { useEffect, useRef, useState, useCallback } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import moment from 'moment'
import valid from 'card-validator'
// redux
import { useDispatch, useSelector } from 'react-redux'
//services
import QuoteService from '../API/QuoteService'
import Charge from '../components/Charge'
import CheckIcon from '../components/Icons/Check'
// icons
import DepartureReturnIcon from '../components/Icons/DepartureReturn'
import ChevronLeftBoxIcon from '../components/Icons/icon/ChevronLeftBox'
import CopyIcon from '../components/Icons/icon/Copy'
import FlightIcon from '../components/Icons/payform/extra/Flight'
import LuggageIcon from '../components/Icons/payform/extra/Luggage'
import PaidIcon from '../components/Icons/payform/extra/Paid'
import RefundIcon from '../components/Icons/payform/extra/Refund'
import SupportIcon from '../components/Icons/payform/extra/Support'
import TicketIcon from '../components/Icons/payform/extra/Ticket'
import AmericanExpressIcon from '../components/Icons/payment/AmericanExpress'
import MaestroIcon from '../components/Icons/payment/Maestro'
import MasterIcon from '../components/Icons/payment/Master'
import VisaIcon from '../components/Icons/payment/Visa'
import WarrningIcon from '../components/Icons/Warning'

// layout
import CheckoutLoyoutMobile from '../components/Layout/Checkout/Mobile'
import CheckoutLoyoutPc from '../components/Layout/Checkout/Pc'
// components
import LineTitle from '../components/LineTitle'
import MailReis from '../components/MailReis'
import PassengerItem from '../components/PassengerItem'
import PassengersProtection from '../components/PassengersProtection'
import SomeDetails from '../components/SomeDetails'
import Tips from '../components/Tips'
import GlassButton from '../components/UI/Buttons/glass'
import PopoverButton from '../components/UI/Buttons/popover'
import DropDown from '../components/UI/DropDown'
import MoveLabelInput from '../components/UI/Input/moveLabel'
import Nav from '../components/UI/Nav'
import Tip from '../components/UI/Tip'
import Toggle from '../components/UI/Toggle'
import PriceBreakdown from '../components/PriceBreakdown'
import LoadingSpinner from '../components/UI/LoadingSpinner'

// hooks
import { EmailValidate, NameValidate, PhoneValidate } from '../hooks/useFormValidate'
import { useTranslation } from "react-i18next"
import { NumberValidate, HolderValidate, ExpirationValidate, CVVValidate } from '../hooks/useCardValidate'
import useIsMobile from '../hooks/useIsMobile';
import useCapitalize from '../hooks/useCapitalize'

// redux
import { setProtectAll, setPaxes } from '../store/passenger'
import {
  setNumber, setHolder, setExpiration, setCvv,
  setBillingCountry, setBillingState, setBillingStreet, setBillingCity, setBillingZip, setCountries, setRegions, setBillingContactEmail, setBillingContactPhone, setBillingContactCountryCode,
} from '../store/billing'
import { setOfferUUID, setSelectedTrip, setTrips } from '../store/quote'
import { setAgent } from '../store/agent'
import { setConfirmItinerary, setConfirmPassengerDetails, setConfirmAcceptedTerms } from '../store/confirmation'
import { setEmail, setPhone, setCountryCode } from '../store/contact'
import { GetTicketProtectionPrice, GetTicketProtectionPriceTotal, GetGrandTotal, FormatPrice } from '../hooks/useGetPrice'
import { setConfirm } from '../store/tips'

// jsons
import phoneJson from '../jsons/phoneJson.json'
import CountryJson from '../jsons/countryJson.json'
import monthJson from '../jsons/monthJson.json'
import s from '../styles/index.module.sass'

export default function MailPage() {

  const dispatch = useDispatch()
  const router = useRouter();

  const isMobile = useIsMobile();

  const GetCapitalize = (string) => useCapitalize(string);

  const { t, i18n } = useTranslation()

  const [stage, setStage] = useState(0)
  const [paymentTab, setPaymentTab] = useState(0)

  const [submitted, setSubmitted] = useState(false)

  const [cardNumberMask, setCardNumberMask] = useState('9999 9999 9999 9999')

  const [toggleTrip, setToggleItinerary] = useState(false)
  const [confirmDetails, setConfirmDetails] = useState(false)
  const [confirmPay, setConfirmPay] = useState(false)

  const [ticketProtaction, setTicketProtaction] = useState(true)
  const [ticketProtactionButton, setTicketProtactionButton] = useState(false)
  const [displayTicketProtection, setDisplayTicketProtection] = useState(false)

  const [disabledProtection, setDisabledProtection] = useState(false)
  const [someDetails, setSomeDetails] = useState(false)
  const [breakdown, setBreakdown] = useState(false);

  const [hasItinerary, setHasItinerary] = useState(false);

  const QUOTE_INFO = useSelector((state) => state.quote)
  const SELECTED_TRIP = useSelector((state) => state.quote.selectedTrip);

  const PASSENGER_INFO = useSelector((state) => state.passenger)

  const BILLING = useSelector((state) => state.billing);
  const CARD_INFO = useSelector((state) => state.billing.card)
  const BILLING_ADRESS = useSelector((state) => state.billing.address)
  const BILLING_CONTACT = useSelector((state) => state.billing.contact)

  const AGENT = useSelector((state) => state.agent);

  const CONTACT = useSelector((state) => state.contact);
  const CONFIRMATION = useSelector((state) => state.confirmation);

  const TIPS_INFO = useSelector((state) => state.tips)

  const TP_TOTAL = GetTicketProtectionPriceTotal(PASSENGER_INFO.paxes, true, SELECTED_TRIP);
  const TP_GRAND_TOTAL = GetTicketProtectionPriceTotal(PASSENGER_INFO.paxes, false, SELECTED_TRIP);

  const GRAND_TOTAL = GetGrandTotal(SELECTED_TRIP.grandTotal, TP_GRAND_TOTAL, (TIPS_INFO.confirm && TIPS_INFO.amount) ? TIPS_INFO.amount : 0);

  // TODO: Delete
  if (false) {
    console.log(123);
  }
  
  let paxValidation = (pax) => {
    let errors = {};
    if (!pax.firstName) errors['firstName'] = true
    if (!pax.lastName) errors['lastName'] = true
    if (!pax.gender) errors['gender'] = true

    if (!pax.dob.month) errors['month'] = true
    if (!pax.dob.day) errors['day'] = true
    if (!pax.dob.year) errors['year'] = true

    if (pax.dob.day?.length != 2 || isNaN(pax.dob.day)) errors['day'] = true
    if (pax.dob.year?.length != 4 || isNaN(pax.dob.year)) errors['year'] = true

    let day = parseInt(pax.dob.day);
    let year = parseInt(pax.dob.year);
    let month = parseInt(pax.dob.month);

    let legsLength = SELECTED_TRIP.itinerary.legs.length;

    let tripType = getTripType(SELECTED_TRIP.itinerary.legs);

    let targetDate;
    //for OW => targetDate = departure date
    if (tripType == 0) {
      targetDate = moment(new Date(SELECTED_TRIP.itinerary.legs[0].depDate));
    } else {
      //for RT,MC => First departure of the last leg
      let flightsLength = SELECTED_TRIP.itinerary.legs[legsLength - 1].flights.length;
      targetDate = moment(new Date(SELECTED_TRIP.itinerary.legs[legsLength - 1].flights[flightsLength - 1].depDate));
    }

    //if pax entered day and month
    if (pax.dob.month && pax.dob.day && !isNaN(day) && pax.dob.day.indexOf('_') == -1) {
      //if day is 0
      if (day == 0) {
        errors['day'] = true;
      }
      //if day is greater than month numbers
      if (day > monthJson.items.find((e) => e.code === pax.dob.month).days) {
        errors['day'] = true;
      }

      //calculating the difference between the target date and pax birthdate
      if (!isNaN(year) && pax.dob.year.indexOf('_') == -1) {
        let returnDate = moment(new Date(year, month - 1, day));

        let yearsDiff = targetDate.diff(returnDate, 'years', true);

        if (yearsDiff >= 0) {
          //ADULT: >12 y.o
          if (pax.ptc == 'ADULT' && yearsDiff < 12) {
            errors['message'] = '*Adult must be over 12 years old'
          }
          //CHILD: 2-12 y.o
          if (pax.ptc == 'CHILD' && (yearsDiff < 2 || yearsDiff > 12)) {
            errors['message'] = '*Child must be between 2 and 12 years old'
          }
          //INFANT: 0-2 y.o
          if (pax.ptc == 'INFANT' && (yearsDiff < 0 || yearsDiff >= 2)) {
            errors['message'] = '*Infant must be under 2 years old'
          }
        } else {
          errors['year'] = true;
        }
      }
    }

    return {
      errors: errors,
      hasErrors: Object.keys(errors).length !== 0
    }
  }

  const nextStage = async () => {
    //Restting the submit status
    setSubmitted(true);

    //Check if user is allowed to go to next stage
    if (stageButtonState()) {
      return;
    } else {
      //Resetting the submit status
      setSubmitted(false);
    }

    //hiding the Price Breakdown for Payment
    if (stage == 1) {
      setBreakdown(false)

      //Locking the Ticket Protection Selection
      if(checkNoneProtected()) {
        setDisabledProtection(true); 
        dispatch(setProtectAll(false))
      } else {
        protectButton();
      }
    }

    setStage(stage + 1)
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    })
  }

  const toggleBreakdown = () => {
    setBreakdown(!breakdown);
  }

  const stageButtonState = () => {
    let status = false

    // stage 0
    if (stage === 0) {

      if (!toggleTrip) status = true

    }
    else if (stage === 1) {

      if (!confirmDetails) status = true

      PASSENGER_INFO.paxes.forEach(user => {
        if (paxValidation(user).hasErrors) status = true
      })

      if (!PhoneValidate(CONTACT.phone)) status = true
      if (!EmailValidate(CONTACT.email)) status = true
      if (!CONTACT.countryCode?.code) status = true

      //if (!ticketProtaction && checkNoneProtected() && !disabledProtection) status = true
    }

    else if (stage === 2) {


      if (!confirmPay) status = true

      //Wire Transfer
      if (paymentTab == 1) {
        return status
      }

      //if (CARD_INFO.number.indexOf('_') != -1) status = true
      //if (CARD_INFO.expiration.indexOf('_') != -1) status = true

      if (!NumberValidate(CARD_INFO.number, CARD_INFO.lengths.number)) status = true;
      if (!HolderValidate(CARD_INFO.holder)) status = true;
      if (!CVVValidate(CARD_INFO.cvv, CARD_INFO.lengths.cvv)) status = true;
      if (!ExpirationValidate(CARD_INFO.expiration)) status = true;
      if (!BILLING_ADRESS.country || !BILLING_ADRESS.state ||
        !BILLING_ADRESS.street || !BILLING_ADRESS.city || !BILLING_ADRESS.zip ||
        !EmailValidate(BILLING_CONTACT.email) || !PhoneValidate(BILLING_CONTACT.phone) || !BILLING_CONTACT.countryCode?.code) status = true
    }

    else status = true

    return status

  }

  const disableStateButton = () => {
    let status = false

    // stage 0
    if (stage === 0) {
      if (!toggleTrip) status = true
    }
    else if (stage === 1) {
      if (!confirmDetails) status = true
    }

    else if (stage === 2) {
      if (!confirmPay) status = true

      //Wire Transfer
      if (paymentTab == 1) {
        return status
      }
    } else status = true

    return status

  }

  const checkAllProtected = () => {
    let protect_count = 0
    PASSENGER_INFO.paxes.forEach(user => {
      if (user.protect) protect_count += 1
    })

    if (protect_count === PASSENGER_INFO.paxes.length) return true
    else return false
  }

  const checkNoneProtected = () => {
    let protect_count = 0
    PASSENGER_INFO.paxes.forEach(user => {
      if (user.protect) protect_count += 1
    })

    if (protect_count === 0) return true
    else return false
  }

  const protectButton = () => {
    setTicketProtaction(true)
    setTicketProtactionButton(true)
    setDisabledProtection(false);

    if (checkNoneProtected()) dispatch(setProtectAll(true))
  }

  /**
   * 
   * @param {*} legs The 
   * @returns 0->One Way 
   *          1->Round Trip 
   *          2->Multi City
   */
  const getTripType = (legs) => {
    switch (legs.length) {
      case 1:
        return 0
      case 2:
        return 1
      default:
        return 2
    }
  }

  const showTicketProtection = () => {
    let show = false;

    for (let pax of PASSENGER_INFO.paxes) {
      if (pax.firstName && pax.lastName && NameValidate(pax.firstName) && NameValidate(pax.lastName)) {
        show = true;
        break;
      }
    }

    return show
  }

  const setSameBillingContact = () => {
    dispatch(setBillingContactEmail(someDetails ? '' : CONTACT.email))
    dispatch(setBillingContactCountryCode(someDetails ? '' : CONTACT.countryCode))
    dispatch(setBillingContactPhone(someDetails ? '' : CONTACT.phone))
  }

  const generateCardNumberMask = (cardInfo) => {
    let pattern = ''
    if (cardInfo && cardInfo.gaps) {

      for (let i = 0; i < CARD_INFO.lengths.number; i++) {
        if (cardInfo.gaps.indexOf(i) != -1) {
          pattern += ' ';
        }
        pattern += '9'
      }
    }
    setCardNumberMask(pattern)
  }

  useEffect(() => {
    if (!ticketProtactionButton) dispatch(setProtectAll(ticketProtaction))
  }, [ticketProtaction])

  useEffect(() => {
    dispatch(setConfirmItinerary(toggleTrip))
  }, [toggleTrip])

  useEffect(() => {
    dispatch(setConfirmPassengerDetails(confirmDetails))
  }, [confirmDetails])

  useEffect(() => {
    dispatch(setConfirmAcceptedTerms(confirmPay))
  }, [confirmPay])

  useEffect(() => {
    dispatch(setBillingState(''))
  }, [BILLING_ADRESS.country])


  const selectTrip = (trip) => {
    const tripCopy = JSON.parse(JSON.stringify(trip));
    const paxes = [...tripCopy.paxes];
    paxes.forEach((pax) => {
      pax.dob = {}
    })

    dispatch(setSelectedTrip(tripCopy))
    dispatch(setPaxes(paxes));

    setDisplayTicketProtection(trip.fields?.ticketProtection?.value || false);
    dispatch(setProtectAll(ticketProtaction && (trip.fields?.ticketProtection?.value || false)))
  }

  const checkout = async () => {

    setSubmitted(true);    
    //Form not Valid
    if (stageButtonState()) {
      return;
    } else {
      //Resetting the submit status
      setSubmitted(false);
    }

    //Show Loading Spinner
    setHasItinerary(false);

    let body = {}

    body.paxes = PASSENGER_INFO.paxes
    body.quote = {
      offerUUID: QUOTE_INFO.offerUUID,
      bookingToken: SELECTED_TRIP.bookingToken,
      itineraryUUID: SELECTED_TRIP.uuid,
    }
    body.contactInfo = CONTACT;
    body.billing = { ...BILLING, ...{ paymentType: paymentTab == 0 ? "CREDIT_CARD" : "WIRE_TRANSFER" } }

    body.confirmations = CONFIRMATION

    let paxesConfirmations = [];
    let paxesTicketProtectionPrice = [];

    PASSENGER_INFO.paxes.forEach(user => {
      paxesConfirmations.push({
        name: [user.firstName, user.middleName, user.lastName].join(' '),
        confirm: (user.protect && displayTicketProtection) || false
      })
      if (user.protect) {
        paxesTicketProtectionPrice.push({
          name: [user.firstName, user.middleName, user.lastName].join(' '),
          price: GetTicketProtectionPrice(user, SELECTED_TRIP)
        })
      }
    })
    body.confirmations = { ...CONFIRMATION, ...{ confirmTicketProtection: paxesConfirmations, confirmTips: TIPS_INFO.confirm } }

    body.price = {
      flightTotal: SELECTED_TRIP.grandTotal,
      tips: TIPS_INFO.confirm ? TIPS_INFO.amount : 0,
      ticketProtection: {
        total: TP_GRAND_TOTAL,
        paxes: paxesTicketProtectionPrice
      },
      grandTotal: GRAND_TOTAL
    }

    await QuoteService.checkout(body)
      .then(response => {
        router.push("/thx");
      })
      .catch(error => {
        var token = router.query.token || router.query.t;
        var preview = query.preview || query.pview;

        setHasItinerary(true);
        router.push({
          pathname: '/error',
          query: { token: token, preview: preview}
        })
      })
  };
  
  useEffect(async () => {
    if (!router.isReady) return;
    const query = router.query;

    var token = query.token || query.t;
    var preview = query.preview || query.pview;

    if (token) {
      QuoteService.get(token, preview).then((response) => {

        if (response.answered) {
          router.push({
            pathname: '/answered',
            query: { token: token, preview: preview }
          })
          
          return;
        }

        //no trips value, redirect to expired page
        if (!response.trips) {
          router.push({
            pathname: '/expired',
            query: { token: token, preview: preview }
          })

          return;
        }

        dispatch(setTrips(response.trips))
        dispatch(setOfferUUID(response.offerUUID))

        selectTrip(response.trips[0]);
        
        dispatch(setCountries(response.countries))
        dispatch(setRegions(response.regions));
        
        dispatch(setAgent(response.agent));

        dispatch(setConfirm(false))
        setHasItinerary(true);
      }).catch(error => {
        //if (error.response.data.status !== 200) {
        // router.push({
        //   pathname: '/error',
        //   query: { title: 'Expired Offer', text: 'Contact your dedicated Travel Concierge for further assistance!', token: token }
        // }, '/error?token=' + token)
        //}
        router.push({
          pathname: '/error',
          query: { token: token, preview: preview }
        })
      })
    } else if (typeof window !== "undefined") {
      window.location = 'https://fivestarairways.com/';
    }

  }, [router.isReady, router.query])

  return isMobile == undefined ? null : (
    <div>
      <CheckoutLoyoutMobile component={
        <Nav stage={stage} texts={["Itinerary", "Passengers", "Payment"]} />
      } nextStage={nextStage} onPay={checkout} buttonState={disableStateButton} stage={stage}
        total={stage == 0 ? FormatPrice(SELECTED_TRIP.grandTotal) : FormatPrice(SELECTED_TRIP.grandTotal + TP_GRAND_TOTAL)}
        breakdown={breakdown} setBreakdown={() => { toggleBreakdown() }} hasItinerary={hasItinerary}>
        {hasItinerary ?
          <section className={s.body}>
            {stage === 0 &&
              <>
                <span className={s['body-title']}>
                  {toggleTrip
                    ? <>{t("greatChoice")}</>
                    : <>{t("pleaseChoose")}</>
                  }
                </span>
                <div className={`${s['body__mode']} ${toggleTrip ? s['body__mode--selected'] : s['body__mode--' + QUOTE_INFO.trips.length]}`}>

                  {QUOTE_INFO.trips.map((item, index) => {

                    if (toggleTrip && SELECTED_TRIP?.bookingToken !== item.bookingToken) return <></>

                    return (
                      <button onClick={() => selectTrip(item)} key={index} className={`${s['body__mode-item']} ${SELECTED_TRIP?.bookingToken === item.bookingToken ? s['body__mode-item--active'] : null}`}>
                        <span className={s['item-title']}>{item.itinerary.platingCarrierName}</span>
                        <span className={s['item-price']}>{FormatPrice(item.adtChdAvg)}</span>
                      </button>
                    )
                  })}

                </div>

                <div className={s['body__title']}>
                  <LineTitle>{t("itinerary")}</LineTitle>
                </div>

                <div className={s['body__container']}>
                  <span className={s['body-trip']}>Trip ID: {SELECTED_TRIP.offerCode}</span>

                  {SELECTED_TRIP.itinerary.legs.map((leg, index) => {
                    return (
                      <div key={index}>
                        <BlockTitle tripType={getTripType(SELECTED_TRIP.itinerary.legs)} index={index} />
                        <MailReis key={index} tripInfo={SELECTED_TRIP?.tripInfo?.legs[index]} leg={leg} mobile={true} />
                      </div>
                    )
                  })}

                  <div className={s['body__footer']}>
                    <span className={s['body__footer-title']}>Booking almost completed</span>
                    <span className={s['body__footer-desc']}>Double check your itinerary and click confirm</span>
                  </div>
                  <div className={s['body__toggle']}>
                    <Toggle
                      active={toggleTrip}
                      change={() => setToggleItinerary(!toggleTrip)}
                    />
                    <span className={s['body__toggle-text']}>I confirm this itinerary</span>
                  </div>
                  <br /><br />
                  <div className={s['body__tip']}>
                    <Tip>You must confirm the itinerary</Tip>
                  </div>

                </div>
              </>
            }
            {stage === 1 &&
              <>
                <div className={s['body__header']}>
                  <button onClick={() => setStage(stage - 1)} className={s['body__header-back']}><ChevronLeftBoxIcon /></button>
                  <div className={s['body__header-info']}>
                    <span className={s['body__header-title']}>Passenger details</span>
                  </div>
                </div>
                <div className={s['body__tip']}>
                  <Tip>Each passengers full name must be entered as it appears on their passport or government issued photo ID. Name changes are not permitted after booking</Tip>
                </div>
                <br /><br />

                {PASSENGER_INFO.paxes.map((item, index) => {
                  return (
                    <div className={s['body__passenger']} key={index}>
                      <LineTitle>passenger {index + 1} · {item.ptc}</LineTitle>
                      <PassengerItem id={index} info={item} mobile={true} validation={paxValidation(item)} submitted={submitted} />
                      {(submitted && paxValidation(item).hasErrors) && (
                        <div className={s["validationError"]} style={{margin: '1rem'}}>
                        <WarrningIcon /> Please fill the required fields marked with *
                        </div>
                      )}
                      <br /><br />
                    </div>
                  )
                })}

                {displayTicketProtection &&
                  <div className={s['body__protection']} key="ticketProtectionBody">
                    <LineTitle> <img src="/protect.svg" alt="Ticket Protection" loading='lazy' /> </LineTitle>

                    <div className={s['body__protection-block']}>
                      <div className={s['body__protection-header']}>
                        <Toggle
                          active={ticketProtaction}
                          change={() => { setTicketProtaction(!ticketProtaction); setDisabledProtection(false); setTicketProtactionButton(false) }}
                        />
                        {(checkNoneProtected() || (!checkNoneProtected() && !ticketProtaction)) &&
                          <span> Yes. Add ticket protection for {FormatPrice(TP_TOTAL)}</span>
                        }
                        {!checkNoneProtected() && ticketProtaction && checkAllProtected() &&
                          <span> Yes. Add ticket protection for {FormatPrice(TP_GRAND_TOTAL)}</span>
                        }
                        {!checkNoneProtected() && ticketProtaction && !checkAllProtected() &&
                          <span> Yes. Add partial ticket protection for {FormatPrice(TP_GRAND_TOTAL)}</span>
                        }                  </div>
                      <div className={s['body__protection-body']}>

                        {!disabledProtection
                          ?
                          <>
                            {!ticketProtaction
                              ?
                              <>
                                <div className={s.top}>
                                  <div className={s.info}>
                                    <span>Five Star Airways Recommends</span>
                                    <p>Protect your ticket, your belongings & most importantly - Yourself!</p>
                                  </div>
                                  {/* <button onClick={() => setDisabledProtection(true)} className={s.close}><CloseBox2Icon /></button> */}
                                </div>
                                <div className={s.shield}><img src="/shield.svg" alt="shield"  /></div>
                                <h1 className={s.title}>Your Tickets are Restrictive</h1>
                                <p className={s.description}>Don’t let unpleasant incidents to spoil your trip. Most Fares are non-refundable and non-exchangable. Let us protect Your trip!</p>
                                <PassengersProtection info={PASSENGER_INFO.paxes} locked={false} trip={SELECTED_TRIP} tpGrandTotal={TP_GRAND_TOTAL}/>
                                <div className={s.btns}>
                                  <PopoverButton onClick={() => { setDisabledProtection(true); dispatch(setProtectAll(false)) }} type="outline">No, Thanks</PopoverButton>
                                  <PopoverButton onClick={() => protectButton()}>Protect</PopoverButton>
                                </div>
                              </>
                              :
                              <>
                                {showTicketProtection() &&
                                  <PassengersProtection info={PASSENGER_INFO.paxes} hideNotProtect={true} trip={SELECTED_TRIP} tpGrandTotal={TP_GRAND_TOTAL} />
                                }
                                <div className={s.benefits}>
                                  <div className={s['benefits__header']}>
                                    <span className={s['benefits__header-title']}>Benefits</span>
                                    <div className={s['benefits__header-line']}></div>
                                    <div className={s['benefits__header-badage']}>9+</div>
                                  </div>
                                  <div className={s['benefits__body']}>

                                    <div className={s['benefits__body-item']}>
                                      <SupportIcon />
                                      <p>Priority access to our VIP concierge desk 24/7</p>
                                    </div>
                                    <div className={s['benefits__body-item']}>
                                      <RefundIcon />
                                      <p>Feeling ill and can't travel? We've got you covered on Travel Credit and Free Exchange</p>
                                    </div>
                                    <div className={s['benefits__body-item']}>
                                      <TicketIcon />
                                      <p>Update your fare class. Receive a “Flexible Fare” ticket</p>
                                    </div>
                                    <div className={s['benefits__body-item']}>
                                      <LuggageIcon />
                                      <p>Lost or mishandled baggage? We’ll retrieve it or pay you $1000 per bag!</p>
                                    </div>
                                    <div className={s['benefits__body-item']}>
                                      <FlightIcon />
                                      <p>Missed connection, delayed or canceled flight? We’ll rebook you for free and claim compensation</p>
                                    </div>
                                    <div className={s['benefits__body-item']}>
                                      <PaidIcon />
                                      <p>Extra perks. Exclusive upgrades, seat/meal assignments, stopover paid by carrier and many more complimentary benefits</p>
                                    </div>

                                  </div>
                                  <div className={s['benefits__footer']}>
                                    <h1 className={s['benefits__footer-title']}>Extra benefits</h1>
                                    <ul className={s['benefits__footer-ul']}>
                                      <li className={s['benefits__footer-li']}><CheckIcon /> Free seat/meal assignment on code-share & restricted carriers</li>
                                      <li className={s['benefits__footer-li']}><CheckIcon /> Pet Travel Consultancy</li>
                                      <li className={s['benefits__footer-li']}><CheckIcon /> Stopover Paid by Carrier guidance</li>
                                    </ul>
                                  </div>
                                </div>
                              </>
                            }
                          </>
                          :
                          <div className={s.protectOff}>
                            <span className={s['protectOff-text']}>Your Trip is Not Protected!</span>
                            <button className={s['protectOff-button']}>PROTECTION OFF</button>
                          </div>
                        }


                      </div>
                    </div>
                  </div>
                }

                <div className={s['body__contacts']}>
                  <LineTitle>Contact Person</LineTitle>
                  <Tip style={{ width: 'auto', marginLeft: '10px', marginRight: '10px', marginTop: '10px' }}>Please enter Emergency Contact, confirmation email and phone number fields are required!</Tip>
                  <p className={s['body__contacts-description']}>By providing my contact details i agree to be contacted in regards to this booking via phone, text and email</p>
                  <div className={s['body__contacts-form']}>
                    <MoveLabelInput move={true} label="Email" value={CONTACT.email} setValue={(e) => dispatch(setEmail(e))} hasError={submitted && !EmailValidate(CONTACT.email)} />
                    <div className={s['grid']}>
                      <DropDown
                        label="Country code"
                        move={false}
                        smallText={true}
                        placeholder="Click to select"
                        value={CONTACT.countryCode?.text}
                        setValue={(e) => { dispatch(setCountryCode(e)) }}
                        items={phoneJson.items}
                        style={{ fontSize: 13 }}
                        relative="true"
                        hasError={submitted && !CONTACT.countryCode?.code}
                      />
                      <MoveLabelInput type="tel" inputmode="tel" label="Phone Number" move={true} smallText={true} value={CONTACT.phone} setValue={(e) => dispatch(setPhone(e))} hasError={submitted && !PhoneValidate(CONTACT.phone)} />
                    </div>
                  </div>
                  {(submitted && (!PhoneValidate(CONTACT.phone) || !EmailValidate(CONTACT.email) || !CONTACT.countryCode?.code)) && (
                    <div className={s["validationError"]} style={{margin: '1rem'}}>
                      <WarrningIcon /> Please fill the required fields marked with *
                    </div>
                  )}
                </div>
                <div className={s['body__confirm']}>
                  <Toggle
                    active={confirmDetails}
                    change={() => setConfirmDetails(!confirmDetails)}
                  />
                  <span className={s['body__confirm-text']}>I confirm that the passanger details are correct</span>
                </div>
              </>
            }
            {stage === 2 &&
              <>
                <div className={s['body__header']}>
                  <button onClick={() => setStage(stage - 1)} className={s['body__header-back']}><ChevronLeftBoxIcon /></button>
                  <div className={s['body__header-info']}>
                    <span className={s['body__header-title']}>Payment</span>
                    <span className={s['body__header-desc']}>All transactions are secure and encrypted</span>
                  </div>
                </div>
                <div className={s['body__payment']}>
                  <div className={s['body__payment-title']}><LineTitle>payment information</LineTitle></div>
                  <div className={s['body__payment-nav']}>

                    <button
                      className={paymentTab === 0 ? s.active : null}
                      onClick={() => setPaymentTab(0)}
                    >Bank Card</button>
                    <button
                      className={paymentTab === 1 ? s.active : null}
                      onClick={() => setPaymentTab(1)}
                    >Wire Transfer</button>

                  </div>

                  {paymentTab === 0 &&
                    <div className={s['body__payment-form']}>
                      <MoveLabelInput
                        label="Credit/Debit Card Number"
                        move={false}
                        smallText={true}
                        placeholder="0000 0000 0000 0000"
                        value={CARD_INFO.number}
                        onChange={(e) => dispatch(setNumber(e.target.value))}
                        mask={cardNumberMask}
                        cardValidation={true}
                        inputmode="tel"
                        generateCardNumberMask={generateCardNumberMask}
                        hasError={submitted && !NumberValidate(CARD_INFO.number, CARD_INFO.lengths.number)}
                      />
                      <div className={s['body__payment-form--two']}>
                        <MoveLabelInput
                          label="Expiration date mm/yy"
                          move={true}
                          value={CARD_INFO.expiration}
                          onChange={(e) => dispatch(setExpiration(e.target.value))}
                          mask="99/99"
                          inputmode="tel"
                          hasError={submitted && !ExpirationValidate(CARD_INFO.expiration)}
                        />
                        <MoveLabelInput
                          label="CVV"
                          move={true}
                          value={CARD_INFO.cvv}
                          onChange={(e) => dispatch(setCvv(e.target.value))}
                          type="password"
                          inputmode="tel"
                          maxLength={CARD_INFO.lengths.cvv}
                          hasError={submitted && !CVVValidate(CARD_INFO.cvv, CARD_INFO.lengths.cvv)}
                        />
                      </div>
                      <MoveLabelInput
                        label="Card Holder’s Name"
                        move={true}
                        value={CARD_INFO.holder}
                        onChange={(e) => dispatch(setHolder(GetCapitalize(e.target.value)))}
                        maxLength={32}
                        hasError={submitted && !HolderValidate(CARD_INFO.holder)}
                      />
                      <div className={s['body__payment-support']}>
                        <span>Support</span>
                        <VisaIcon />
                        <MasterIcon />
                        <MaestroIcon />
                        <AmericanExpressIcon />
                      </div>
                      {submitted && (!NumberValidate(CARD_INFO.number, CARD_INFO.lengths.number) || !HolderValidate(CARD_INFO.holder) || !CVVValidate(CARD_INFO.cvv, CARD_INFO.lengths.cvv) || !ExpirationValidate(CARD_INFO.expiration)) && (
                      <div className={s["validationError"]} >
                        <WarrningIcon /> Please fill the required fields marked with *
                      </div>
                      )}
                    </div>
                  }
                </div>
                {paymentTab === 0 &&
                  <>
                    <div className={s['body__billing']}>
                      <div className={s['body__billing-title']}><LineTitle>Billing address</LineTitle></div>
                      <div className={s['body__billing-form']}>
                        <Tip style={{ width: '100%' }}>Your billing address must match exactly the address on your credit card statement</Tip>
                        <DropDown
                          label="Country"
                          move={true}
                          items={CountryJson.list}
                          setValue={(e) => { dispatch(setBillingCountry(e.text)) }}
                          value={BILLING_ADRESS.country}
                          hasError={submitted && !BILLING_ADRESS.country}
                        />
                        {BILLING_ADRESS.country === 'United States'
                          ?
                          <DropDown
                            label="State"
                            move={true}
                            items={CountryJson.list.find((e) => e.text === BILLING_ADRESS.country)?.states}
                            setValue={(e) => { dispatch(setBillingState(e.text)) }}
                            value={BILLING_ADRESS.state}
                            hasError={submitted && !BILLING_ADRESS.state}
                          />
                          :
                          <MoveLabelInput label="State" move={true} value={BILLING_ADRESS.state} setValue={(e) => { dispatch(setBillingState(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.state}/>
                        }
                        <MoveLabelInput label="Street Address" move={true} value={BILLING_ADRESS.street} setValue={(e) => { dispatch(setBillingStreet(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.street}/>
                        <MoveLabelInput label="City" move={true} value={BILLING_ADRESS.city} setValue={(e) => { dispatch(setBillingCity(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.city}/>
                        <MoveLabelInput label="Zip/Postal Code" move={true} value={BILLING_ADRESS.zip} setValue={(e) => { dispatch(setBillingZip(e)) }} maxLength={12} hasError={submitted && !BILLING_ADRESS.zip}/>
                      </div>
                    </div>
                    <SomeDetails
                      status={someDetails}
                      submitted={submitted}
                      onChange={() => { setSomeDetails(!someDetails); setSameBillingContact() }}

                      countryCode={CONTACT.countryCode}
                      someCountryCode={BILLING_CONTACT.countryCode}
                      setSomeCountryCode={(e) => dispatch(setBillingContactCountryCode(e))}

                      phoneInput={CONTACT.phone}
                      somePhoneInput={BILLING_CONTACT.phone}
                      setSomePhoneInput={(e) => dispatch(setBillingContactPhone(e))}

                      emailInput={CONTACT.email}
                      someEmailInput={BILLING_CONTACT.email}
                      setSomeEmailInput={(e) => dispatch(setBillingContactEmail(e))}
                    />
                    <div style={{margin: '0rem 0rem 3rem 0rem'}}> 
                      {submitted && (!BILLING_ADRESS.country || !BILLING_ADRESS.state ||
        !BILLING_ADRESS.street || !BILLING_ADRESS.city || !BILLING_ADRESS.zip ||
        !EmailValidate(BILLING_CONTACT.email) || !PhoneValidate(BILLING_CONTACT.phone) || !BILLING_CONTACT.countryCode?.code) && (
                        <div className={s["validationError"]} style={{margin:'1rem'}}>
                          <WarrningIcon /> Please fill the required fields marked with *
                        </div>
                      )}
                    </div>
                  </>
                }
                {paymentTab === 1 &&
                  <div className={s['body__transfer']}>
                    <span className={s['body__transfer-title']}>Ask your agent for bank account details</span>

                    <div className={s['body__transfer-item']}>
                      <span className={s['item-title']}>Beneficiar Name</span>
                      <div className={s['item-info']}>
                        <p className={s['item-text']}>Five Star Airways inc</p>
                        <CopyToClipboard text={"Five Star Airways inc"}>
                          <button className={s['item-btn']}><CopyIcon /></button>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={s['body__transfer-item']}>
                      <span className={s['item-title']}>Benef. Address</span>
                      <div className={s['item-info']}>
                        <p className={s['item-text']}>5840 SW 57th way,<br />Davie, FL 33314, USA</p>
                        <CopyToClipboard text={"5840 SW 57th way, Davie, FL 33314, USA"}>
                          <button className={s['item-btn']}><CopyIcon /></button>
                        </CopyToClipboard>
                      </div>
                    </div>

                    <div className={s['body__transfer-line']}></div>

                    <p className={s['body__transfer-description']}>Please be reminded that it may take from 2 to 5 business days for some wires to get posted on our account, depending on your bank and country of origin.</p>

                  </div>
                }
                <Tips />
                <Charge
                  footer={false}
                  mobile={false}
                  stage={stage}
                  style={stage === 2 ? { margin: 0 } : null}
                  value={confirmPay}
                  setValue={setConfirmPay}
                  onProtect={protectButton}
                  b_allProtected={!checkNoneProtected()}
                  buttonState={disableStateButton}
                  onPay={checkout}
                  tpGrandTotal={TP_GRAND_TOTAL}
                  grandTotal={GRAND_TOTAL}
                  tip={TIPS_INFO}
                  trip={SELECTED_TRIP}
                  tripType={getTripType(SELECTED_TRIP.itinerary.legs)}
                  paymentTab={paymentTab}
                  displayTicketProtection={displayTicketProtection}
                />
                <br /><br />
              </>
            }

            <div className={breakdown ? (`${[s['body__breakdown'], s['body__breakdown--show']].join(' ')}`) : s['body__breakdown']}>
              <PriceBreakdown
                footer={false}
                stage={stage}
                b_allProtected={!checkNoneProtected()}
                tpGrandTotal={TP_GRAND_TOTAL}
                grandTotal={GRAND_TOTAL}
                tip={TIPS_INFO}
                trip={SELECTED_TRIP}
                tripType={getTripType(SELECTED_TRIP.itinerary.legs)}
                showTP={stage != 0 && displayTicketProtection}
                showTips={stage == 2}
              />
            </div>

          </section>
          :
          <section className={s.body}>
            <LoadingSpinner />
          </section>
        }
      </CheckoutLoyoutMobile>

      <CheckoutLoyoutPc component={
        <Nav stage={stage} texts={[t("itinerary"), "Passengers", "Payment"]} />

      } hasItinerary={hasItinerary} >
        {hasItinerary ?
          <div className={s['pcContainer']}>

            <div className={s['pcContainer__body']}>
              {stage === 0 &&
                <>
                  <span className={s['body-title']} style={toggleTrip ? { textAlign: 'center' } : null}>
                    {toggleTrip
                      ? <>{t("greatChoice")}</>
                      : <>{t("pleaseChoose")}</>
                    }
                  </span>
                  <div className={`${s['body__mode']} ${toggleTrip ? s['body__mode--selected'] : s['body__mode--' + QUOTE_INFO.trips.length]}`}>

                    {QUOTE_INFO.trips.map((item, index) => {

                      if (toggleTrip && SELECTED_TRIP?.bookingToken !== item.bookingToken) return <></>

                      return (
                        <button onClick={() => selectTrip(item)} key={index} className={`${s['body__mode-item']} ${SELECTED_TRIP?.bookingToken === item.bookingToken ? s['body__mode-item--active'] : null}`}>
                          <span className={s['item-title']}>{item.itinerary.platingCarrierName}</span>
                          <span className={s['item-price']}>{FormatPrice(item.adtChdAvg)}</span>
                        </button>
                      )
                    })}

                  </div>

                  <div className={s['body__title']}>
                    <LineTitle>Itinerary</LineTitle>
                  </div>

                  <div className={s['body__container']}>
                    <span className={s['body-trip']}>Trip ID: {SELECTED_TRIP.offerCode}</span>

                    {SELECTED_TRIP.itinerary.legs.map((leg, index) => {
                      return (
                        <div key={index}>
                          <BlockTitle tripType={getTripType(SELECTED_TRIP.itinerary.legs)} index={index} />
                          <MailReis key={index} tripInfo={SELECTED_TRIP?.tripInfo?.legs[index]} leg={leg} />
                        </div>
                      )
                    })}


                  </div>
                </>
              }
              {stage === 1 &&
                <>
                  <div className={s['body__header']}>
                    <button onClick={() => setStage(stage - 1)} className={s['body__header-back']}><ChevronLeftBoxIcon /></button>
                    <div className={s['body__header-info']}>
                      <span className={s['body__header-title']}>Passenger details</span>
                    </div>
                  </div>
                  <div className={s['body__tip']}>
                    <Tip style={{ width: '100%' }}>Each passengers full name must be entered as it appears on their passport or government issued photo ID. Name changes are not permitted after booking</Tip>
                  </div>

                  {PASSENGER_INFO.paxes.map((item, index) => {
                    return (
                      <div className={s['body__passenger']} key={index}>
                        <br /><br />
                        <LineTitle>passenger {index + 1} · {item.ptc}</LineTitle>
                        <PassengerItem id={index} info={item} validation={paxValidation(item)} submitted={submitted} />
                        {(submitted && paxValidation(item).hasErrors) && (
                          <div className={s["validationError"]} style={{padding: '1rem 0rem'}}>
                            <WarrningIcon /> Please fill the required fields marked with *
                          </div>
                        )}
                        <br />
                      </div>
                    )
                  })}

                  {displayTicketProtection &&
                    <div className={s['body__protection']} key="ticketProtectionBody">
                      <LineTitle> <img src="/protect.svg" alt="Ticket Protection" loading='lazy' /> </LineTitle>

                      <div className={s['body__protection-block']}>
                        <div className={s['body__protection-header']}>
                          <Toggle
                            active={ticketProtaction}
                            change={() => { setTicketProtaction(!ticketProtaction); setDisabledProtection(false); setTicketProtactionButton(false) }}
                          />
                          {(checkNoneProtected() || (!checkNoneProtected() && !ticketProtaction)) &&
                            <span> Yes. Add ticket protection for {FormatPrice(TP_TOTAL)}</span>
                          }
                          {!checkNoneProtected() && ticketProtaction && checkAllProtected() &&
                            <span> Yes. Add ticket protection for {FormatPrice(TP_GRAND_TOTAL)}</span>
                          }
                          {!checkNoneProtected() && ticketProtaction && !checkAllProtected() &&
                            <span> Yes. Add partial ticket protection for {FormatPrice(TP_GRAND_TOTAL)}</span>
                          }

                        </div>
                        <div className={s['body__protection-body']}>

                          {!disabledProtection
                            ?
                            <>
                              {!ticketProtaction
                                ?
                                <div style={{ padding: '0 8.6rem' }}>
                                  <div className={s.top}>
                                    <div className={s.info} style={{ textAlign: 'center', width: '100%' }}>
                                      <span>Five Star Airways Recommends</span>
                                      <p>Protect your ticket, your belongings & most importantly - Yourself!</p>
                                    </div>
                                  </div>
                                  <div className={s.shield}><img src="/shield.svg" alt="shield"  /></div>
                                  <h1 className={s.title}>Your Tickets are Restrictive</h1>
                                  <p className={s.description}>Don’t let unpleasant incidents to spoil your trip. Most Fares are non-refundable and non-exchangable. Let us protect Your trip!</p>
                                  <div style={{ padding: '2.5rem 1.5rem 1.5rem 1.5rem' }}>
                                    <PassengersProtection info={PASSENGER_INFO.paxes} locked={false} trip={SELECTED_TRIP} tpGrandTotal={TP_GRAND_TOTAL} />
                                  </div>
                                  <div className={s.btns}>
                                    <PopoverButton onClick={() => { setDisabledProtection(true); dispatch(setProtectAll(false)) }} type="outline">No, Thanks</PopoverButton>
                                    <PopoverButton onClick={() => protectButton()}>Protect</PopoverButton>
                                  </div>
                                </div>
                                :
                                <>
                                  {showTicketProtection() &&
                                    <div style={{ padding: '2.5rem 1.5rem 1.5rem 1.5rem' }}>
                                      <PassengersProtection info={PASSENGER_INFO.paxes} hideNotProtect={true} trip={SELECTED_TRIP} tpGrandTotal={TP_GRAND_TOTAL} />
                                    </div>
                                  }
                                  <div className={s.benefits}>
                                    <div className={s['benefits__header']}>
                                      <span className={s['benefits__header-title']}>Benefits</span>
                                      <div className={s['benefits__header-line']}></div>
                                      <div className={s['benefits__header-badage']}>6+</div>
                                    </div>
                                    <div className={`${s['benefits__body']} ${s['benefits__body--pc']}`}>

                                      <div className={s['benefits__body-item']}>
                                        <SupportIcon />
                                        <p>Priority access to our VIP concierge desk 24/7</p>
                                      </div>
                                      <div className={s['benefits__body-item']}>
                                        <RefundIcon />
                                        <p>Feeling ill and can't travel? We've got you covered on Travel Credit and Free Exchange</p>
                                      </div>
                                      <div className={s['benefits__body-item']}>
                                        <TicketIcon />
                                        <p>Update your fare class. Receive a “Flexible Fare” ticket</p>
                                      </div>
                                      <div className={s['benefits__body-item']}>
                                        <LuggageIcon />
                                        <p>Lost or mishandled baggage? We’ll retrieve it or pay you $1000 per bag!</p>
                                      </div>
                                      <div className={s['benefits__body-item']}>
                                        <FlightIcon />
                                        <p>Missed connection, delayed or canceled flight? We’ll rebook you for free and claim compensation</p>
                                      </div>
                                      <div className={s['benefits__body-item']}>
                                        <PaidIcon />
                                        <p>Extra perks. Exclusive upgrades, seat/meal assignments, stopover paid by carrier and many more complimentary benefits</p>
                                      </div>

                                    </div>
                                    <div className={s['benefits__footer']}>
                                      <h1 className={s['benefits__footer-title']}>Extra benefits</h1>
                                      <ul className={s['benefits__footer-ul']}>
                                        <li className={s['benefits__footer-li']}><CheckIcon /> Free seat/meal assignment on code-share & restricted carriers</li>
                                        <li className={s['benefits__footer-li']}><CheckIcon /> Pet Travel Consultancy</li>
                                        <li className={s['benefits__footer-li']}><CheckIcon /> Stopover Paid by Carrier guidance</li>
                                      </ul>
                                    </div>
                                  </div>
                                </>
                              }
                            </>
                            :
                            <div className={s.protectOff}>
                              <span className={s['protectOff-text']}>Your Trip is Not Protected!</span>
                              <button className={s['protectOff-button']}>PROTECTION OFF</button>
                            </div>
                          }


                        </div>
                      </div>
                    </div>
                  }

                </>
              }
              {stage === 2 &&
                <>
                  <div className={s['body__header']}>
                    <button onClick={() => setStage(stage - 1)} className={s['body__header-back']}><ChevronLeftBoxIcon /></button>
                    <div className={s['body__header-info']}>
                      <span className={s['body__header-title']}>Payment</span>
                      <span className={s['body__header-desc']}>All transactions are secure and encrypted</span>
                    </div>
                  </div>
                  <div className={s['body__payment']}>
                    <div className={s['body__payment-title']}><LineTitle>payment information</LineTitle></div>
                    <div className={s['body__payment-nav']}>

                      <button
                        className={paymentTab === 0 ? s.active : null}
                        onClick={() => setPaymentTab(0)}
                      >Bank Card</button>
                      <button
                        className={paymentTab === 1 ? s.active : null}
                        onClick={() => setPaymentTab(1)}
                      >Wire Transfer</button>

                    </div>

                  {paymentTab === 0 &&
                    <div className={s['body__payment-form']}>
                      <MoveLabelInput
                        label="Credit/Debit Card Number"
                        move={false}
                        smallText={true}
                        placeholder="0000 0000 0000 0000"
                        value={CARD_INFO.number}
                        onChange={(e) => dispatch(setNumber(e.target.value))}
                        mask={cardNumberMask}
                        cardValidation={true}
                        inputmode="tel"
                        generateCardNumberMask={generateCardNumberMask}
                        hasError={submitted && !NumberValidate(CARD_INFO.number, CARD_INFO.lengths.number)}
                      />
                      <div className={s['body__payment-form--two']}>
                        <MoveLabelInput
                          label="Expiration date mm/yy"
                          move={true}
                          value={CARD_INFO.expiration}
                          onChange={(e) => dispatch(setExpiration(e.target.value))}
                          mask="99/99"
                          inputmode="tel"
                          hasError={submitted && !ExpirationValidate(CARD_INFO.expiration)}
                        />
                        <MoveLabelInput
                          label="CVV"
                          move={true}
                          value={CARD_INFO.cvv}
                          onChange={(e) => dispatch(setCvv(e.target.value))}
                          type="password"
                          inputmode="tel"
                          maxLength={CARD_INFO.lengths.cvv}
                          hasError={submitted && !CVVValidate(CARD_INFO.cvv, CARD_INFO.lengths.cvv)}
                        />
                      </div>
                      <MoveLabelInput label="Card Holder’s Name" move={true} value={CARD_INFO.holder} onChange={(e) => dispatch(setHolder(GetCapitalize(e.target.value)))} maxLength={32} hasError={submitted && !HolderValidate(CARD_INFO.holder)} />
                      <div className={s['body__payment-support']}>
                        <span>Support</span>
                        <VisaIcon />
                        <MasterIcon />
                        <MaestroIcon />
                        <AmericanExpressIcon />
                      </div>
                      {submitted && (!NumberValidate(CARD_INFO.number, CARD_INFO.lengths.number) || !HolderValidate(CARD_INFO.holder) || !CVVValidate(CARD_INFO.cvv, CARD_INFO.lengths.cvv) || !ExpirationValidate(CARD_INFO.expiration)) && (
                        <div className={s["validationError"]} >
                          <WarrningIcon /> Please fill the required fields marked with *
                        </div>
                      )}
                    </div>
                  }
                </div>
                {paymentTab === 0 &&
                  <>
                    <div className={s['body__billing']}>
                      <div className={s['body__billing-title']}><LineTitle>Billing address</LineTitle></div>
                      <div className={s['pcBiling']}>
                        <Tip style={{ width: '100%' }}>Your billing address must match exactly the address on your credit card statement</Tip>
                        <div className={s['pcBiling-form']}>
                          <DropDown
                            label="Country"
                            move={true}
                            items={CountryJson.list}
                            setValue={(e) => { dispatch(setBillingCountry(e.text)) }}
                            value={BILLING_ADRESS.country}
                            hasError={submitted && !BILLING_ADRESS.country}
                          />
                          {BILLING_ADRESS.country === 'United States'
                            ?
                            <DropDown
                              label="State"
                              move={true}
                              items={CountryJson.list.find((e) => e.text === BILLING_ADRESS.country)?.states}
                              setValue={(e) => { dispatch(setBillingState(e.text)) }}
                              value={BILLING_ADRESS.state}
                              hasError={submitted && !BILLING_ADRESS.state}
                            />
                            :
                            <MoveLabelInput label="State" move={true} value={BILLING_ADRESS.state} setValue={(e) => { dispatch(setBillingState(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.state} />
                          }
                          {/* <MoveLabelInput label="State" move={true} value={biling_state} setValue={setBiling_state} maxLength={32} /> */}
                        </div>
                        <div className={s['pcBiling-form2']}>
                          <MoveLabelInput label="Street Address" move={true} value={BILLING_ADRESS.street} setValue={(e) => { dispatch(setBillingStreet(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.street} />
                          <MoveLabelInput label="City" move={true} value={BILLING_ADRESS.city} setValue={(e) => { dispatch(setBillingCity(GetCapitalize(e))) }} maxLength={32} hasError={submitted && !BILLING_ADRESS.city} />
                          <MoveLabelInput label="Zip/Postal Code" move={true} value={BILLING_ADRESS.zip} setValue={(e) => { dispatch(setBillingZip(e)) }} maxLength={12} hasError={submitted && !BILLING_ADRESS.zip} />
                        </div>
                      </div>
                      <SomeDetails
                        status={someDetails}
                        submitted={submitted}
                        onChange={() => { setSomeDetails(!someDetails); setSameBillingContact() }}

                        countryCode={CONTACT.countryCode}
                        someCountryCode={BILLING_CONTACT.countryCode}
                        setSomeCountryCode={(e) => dispatch(setBillingContactCountryCode(e))}

                        phoneInput={CONTACT.phone}
                        somePhoneInput={BILLING_CONTACT.phone}
                        setSomePhoneInput={(e) => dispatch(setBillingContactPhone(e))}

                        emailInput={CONTACT.email}
                        someEmailInput={BILLING_CONTACT.email}
                        setSomeEmailInput={(e) => dispatch(setBillingContactEmail(e))}
                      />
                      <div style={{margin: '0rem 0rem 3rem 0rem'}}> 
                      {submitted && (!BILLING_ADRESS.country || !BILLING_ADRESS.state ||
        !BILLING_ADRESS.street || !BILLING_ADRESS.city || !BILLING_ADRESS.zip ||
        !EmailValidate(BILLING_CONTACT.email) || !PhoneValidate(BILLING_CONTACT.phone) || !BILLING_CONTACT.countryCode?.code) && (
                        <div className={s["validationError"]} style={{margin:'1rem'}}>
                          <WarrningIcon /> Please fill the required fields marked with *
                        </div>
                      )}
                      </div>
                    </div>
                  </>
                  }
                  {paymentTab === 1 &&
                    <div className={s['body__transfer']}>
                      <span className={s['body__transfer-title']}>Ask your agent for bank account details</span>

                      <div className={s['body__transfer-item']}>
                        <span className={s['item-title']}>Beneficiar Name</span>
                        <div className={s['item-info']}>
                          <p className={s['item-text']}>Five Star Airways inc</p>
                          <CopyToClipboard text={"Five Star Airways inc"}>
                            <button className={s['item-btn']}><CopyIcon /></button>
                          </CopyToClipboard>
                        </div>
                      </div>

                      <div className={s['body__transfer-item']}>
                        <span className={s['item-title']}>Benef. Address</span>
                        <div className={s['item-info']}>
                          <p className={s['item-text']}>5840 SW 57th way, Davie, FL 33314, USA</p>
                          <CopyToClipboard text={"5840 SW 57th way, Davie, FL 33314, USA"}>
                            <button className={s['item-btn']}><CopyIcon /></button>
                          </CopyToClipboard>
                        </div>
                      </div>

                      <div className={s['body__transfer-line']}></div>

                      <p className={s['body__transfer-description']}>Please be reminded that it may take from 2 to 5 business days for some wires to get posted on our account, depending on your bank and country of origin.</p>

                    </div>
                  }
                  <Tips />
                </>
              }

            </div>

            <div className={s['pcContainer__aside']} style={stage === 2 ? { padding: 0 } : null}>

              {stage === 0 &&
                <>
                  <h1 className={s['pcContainer__aside-title']}>Booking almost completed</h1>
                  <p className={s['pcContainer__aside-description']}>Double check your itinerary and click confirm</p>
                  <div className={s['body__toggle']}>
                    <Toggle
                      active={toggleTrip}
                      change={() => setToggleItinerary(!toggleTrip)}
                    />
                    <span className={s['body__toggle-text']}>I confirm this itinerary</span>
                  </div>
                  <div style={{ marginTop: '2.5rem' }}></div>
                  <PriceBreakdown
                    footer={false}
                    stage={stage}
                    value={confirmPay}
                    b_allProtected={!checkNoneProtected()}
                    tpGrandTotal={TP_GRAND_TOTAL}
                    grandTotal={GRAND_TOTAL}
                    tip={TIPS_INFO}
                    trip={SELECTED_TRIP}
                    tripType={getTripType(SELECTED_TRIP.itinerary.legs)}
                    showTP={stage != 0 && displayTicketProtection}
                    showTips={stage == 2}
                  />
                  <div className={s['pcContainer__aside-price']}>
                    <div className={s['text']}>{FormatPrice(SELECTED_TRIP.grandTotal)}</div>
                    <GlassButton onClick={nextStage} disabled={disableStateButton()}>Next</GlassButton>

                  </div>

                </>
              }
              {stage === 1 &&
                <>
                  <Tip>Please enter Emergency Contact, confirmation email and phone number fields are required!</Tip>
                  <br />
                  <h1 className={s['pcContainer__aside-title']}>Contact Person</h1>
                  <p className={s['pcContainer__aside-description']}>By providing my contact details I agree to be contacted in regard to this booking via phone, text and email</p>
                  <br /><br />
                  <div className={s['pcContainer__aside-inputs']}>
                    <MoveLabelInput move={true} label="Email" value={CONTACT.email} setValue={(e) => dispatch(setEmail(e))} hasError={submitted && !EmailValidate(CONTACT.email)} />
                    <div className={s.grid}>
                      <DropDown
                        label="Country code"
                        move={false}
                        smallText={true}
                        placeholder="Click to select"
                        value={CONTACT.countryCode?.text}
                        setValue={(e) => { dispatch(setCountryCode(e)) }}
                        items={phoneJson.items}
                        style={{ fontSize: 13 }}
                        hasError={submitted && !CONTACT.countryCode?.code}
                      />
                      <MoveLabelInput inputmode="tel" type="tel" label="Phone Number" move={true} value={CONTACT.phone} setValue={(e) => dispatch(setPhone(e))} hasError={submitted && !PhoneValidate(CONTACT.phone)} />
                    </div>
                  </div>
                  {(submitted && (!PhoneValidate(CONTACT.phone) || !EmailValidate(CONTACT.email) || !CONTACT.countryCode?.code)) && (
                   <div className={s["validationError"]} style={{padding: '1rem 0rem'}}>
                    <WarrningIcon /> Please fill the required fields marked with *
                    </div>
                  )}
                  <div className={s['body__toggle']}>
                    <div style={{ marginTop: '5.2rem' }}></div>
                    <Toggle
                      active={confirmDetails}
                      change={() => setConfirmDetails(!confirmDetails)}
                    />
                    <span className={s['body__toggle-text']}>I confirm that the passenger details are correct</span>
                  </div>
                  <PriceBreakdown
                    footer={false}
                    stage={stage}
                    b_allProtected={!checkNoneProtected()}
                    tpGrandTotal={TP_GRAND_TOTAL}
                    grandTotal={GRAND_TOTAL}
                    tip={TIPS_INFO}
                    trip={SELECTED_TRIP}
                    tripType={getTripType(SELECTED_TRIP.itinerary.legs)}
                    showTP={stage != 0 && displayTicketProtection}
                    showTips={stage == 2}
                  />
                  <div className={s['pcContainer__aside-price']}>
                    <span className={s['text']}>{FormatPrice(SELECTED_TRIP.grandTotal + TP_GRAND_TOTAL)}</span>
                    <GlassButton onClick={nextStage} disabled={disableStateButton()}>Next</GlassButton>
                  </div>

                </>
              }
              {stage === 2 &&
                <Charge
                  footer={false}
                  stage={stage}
                  style={stage === 2 ? { margin: 0 } : null}
                  value={confirmPay}
                  setValue={setConfirmPay}
                  onProtect={protectButton}
                  b_allProtected={!checkNoneProtected()}
                  buttonState={disableStateButton}
                  onPay={checkout}
                  tpGrandTotal={TP_GRAND_TOTAL}
                  grandTotal={GRAND_TOTAL}
                  tip={TIPS_INFO}
                  trip={SELECTED_TRIP}
                  tripType={getTripType(SELECTED_TRIP.itinerary.legs)}
                  paymentTab={paymentTab}
                  displayTicketProtection={displayTicketProtection}
                />
              }

              {stage !== 2 &&
                <>
                  <div className={s['pcContainer__aside-agent']}>
                    <img className={s.avatar} src="/agent/agent.png"  alt="agent" />
                    <span className={s.name}>{AGENT.info.firstName} {AGENT.info.lastName}</span>
                    <div className={s.phone}>
                      <img src="/phone.svg"  alt="phone" />
                      <a href={`tel:${'+1' + AGENT.info.phone}`}>{AGENT.info.phone}</a>
                    </div>
                    <a className={s.mail} href={`mailto:${AGENT.info.email}`}>{AGENT.info.email}</a>
                  </div>
                  <p className={s['pcContainer__aside-footer']}>If you have any questions during payment form completion, just give me a call or send me an email, whichever works best for you.</p>
                </>
              }
            </div>
          </div>
          :
          <LoadingSpinner />
        }

      </CheckoutLoyoutPc>
    </div>
  )
}

const BlockTitle = ({ tripType = 0, index }) => {
  return (
    <div className={s.block}>
      <div className={`${s['block__svg']} ${(tripType == 1 && index % 2 == 1) ? s['block__svg--revers'] : null}`}><DepartureReturnIcon /></div>
      <span className={s['block-title']}>
        {tripType == 0 && "Departing Flight"}
        {tripType == 1 && index % 2 == 0 && "Departing Flight"}
        {tripType == 1 && index % 2 == 1 && "Returning Flight"}
        {tripType == 2 && "Flight " + (index + 1)}
      </span>
    </div>
  )
}