import s from "./index.module.sass";

// redux
import { FormatPrice } from "../../hooks/useGetPrice";

export default function PriceBreakdown({
	footer = true,
	stage,
	b_allProtected,
	mobile = false,
	tpGrandTotal,
	grandTotal,
	tip,
	trip,
	tripType,
	showTP = false,
	showTips = false,
	...props
}) {
	return (
		<div className={s["breakdown"]} {...props}>
			<div className={`${s["breakdown-info"]} ${s["line--spacer"]}`}>
				<div div className={s.line}>
					<span className={`${s["line-title"]} ${s["line-title--bold"]}`}>
						<b>
							{tripType == 0 && "One-Way Flight"}
							{tripType == 1 && "Round-Trip Flight"}
							{tripType == 2 && "Multi-City Flight"}
						</b>
					</span>
					<span className={`${s["line-value"]} ${s["line-value--bold"]}`}>
						<b>{FormatPrice(trip.grandTotal)}</b>
					</span>
				</div>
				<div className={s.line}>
					<span className={s["line-title"]}>
						Adults x {trip.adultInfo.number}
					</span>
					<span className={s["line-value"]}>
						{FormatPrice(trip.adultInfo.price)}
					</span>
				</div>
				{trip.childInfo.number > 0 && (
					<div className={s.line}>
						<span className={s["line-title"]}>
							Children x {trip.childInfo.number}
						</span>
						<span className={s["line-value"]}>
							{FormatPrice(trip.childInfo.price)}
						</span>
					</div>
				)}
				{trip.infInfo.number > 0 && (
					<div className={s.line}>
						<span className={s["line-title"]}>
							Infants x {trip.infInfo.number}
						</span>
						<span className={s["line-value"]}>
							{FormatPrice(trip.infInfo.price)}
						</span>
					</div>
				)}
			</div>

			<div></div>
			{(showTP || (showTips && tip.confirm)) && (
				<div className={`${s["breakdown-info"]} ${s["line--spacer"]}`}>
					<div className={s.line}>
						<span className={`${s["line-title"]} ${s["line-title--bold"]}`}>
							<b>Additional Service</b>
						</span>
						<span className={`${s["line-value"]} ${s["line-value--bold"]}`}>
							<b>{FormatPrice(tpGrandTotal + (showTips ? (tip.confirm ? tip.amount : 0) : 0))}</b>
						</span>
					</div>
					{showTP &&
						<div className={`${s.line} ${s["line--spacer"]}`}>
							<span className={s["line-title"]}>Ticket Protection</span>
							<span className={s["line-value"]}>{FormatPrice(tpGrandTotal)}</span>
						</div>
					}
					{showTips && tip.confirm && (
						<div className={s.line}>
							<span className={s["line-title"]}>
								Tips for an awesome service
							</span>
							<span className={s["line-value"]}>{FormatPrice(tip.amount)}</span>
						</div>
					)}
				</div>
			)}

			{footer && (
				<div className={s["breakdown-footer"]}>
					<span className={s.title}>Total to be paid</span>
					<span className={s.value}>{FormatPrice(grandTotal)}</span>
				</div>
			)}
		</div>
	);
}
