import { useRouter } from "next/router";
import s from "./index.module.sass";

// icons
import Full2Logo from "../../../Icons/Logo/Full2";
import ChevronDownBoxIcon from "../../../Icons/icon/ChevronDownBox";
import VisaIcon from "../../../Icons/Brand/Visa";
import AstaIcon from "../../../Icons/Brand/Asta";
import TruspilotIcon from "../../../Icons/Brand/Truspilot";
import PataIcon from "../../../Icons/Brand/Pata";
import TruspilotIcon2 from "../../../Icons/Brand/Truspilot2";
import BbbIcon from "../../../Icons/Brand/Bbb";
import DiscoverIcon from "../../../Icons/Brand/Discover";
import ActaIcon from "../../../Icons/Brand/Acta";
import AmericanExpressIcon from "../../../Icons/Brand/AmericanExpress";
import MasterCardIcon from "../../../Icons/Brand/Mastercard";
import NortonIcon from "../../../Icons/Brand/Norton";
import DinersClubIcon from "../../../Icons/Brand/DinersClub";
import Charge from "../../../Charge";
// components
import AgentCard from "../../../AgentCard";
import GlassButton from "../../../UI/Buttons/glass";
import PriceBreakdown from "./../../../PriceBreakdown/index";

export default function CheckoutMobileLayout({
	children,
	component,
	nextStage,
	onPay,
	buttonState,
	stage,
	total,
	breakdown,
	setBreakdown,
	hasItinerary,
}) {
	const router = useRouter();

	return (
		<section className={s.layout} >
			<header className={s["layout__header"]}>
				<div className={s["layout__header-items"]}>
					<a href="https://fivestarairways.com/">
						<Full2Logo white={true} />
					</a>
					{hasItinerary && <AgentCard />}
				</div>
				<h1 className={s["layout__header-title"]}>
					Review Details & Confirm Your Trip
				</h1>
				{component}
			</header>
			{children}

			{hasItinerary && (
				<>
					{stage !== 2 ? (
						<footer className={s["layout__footer"]}>
							<button
								onClick={setBreakdown}
								className={
									!breakdown
										? s["layout__footer-button"]
										: s["layout__footer-button-revers"]
								}>
								<ChevronDownBoxIcon />
							</button>
							<div className={s["layout__footer-price"]}>{total}</div>
							<div className={s["layout__footer-btn"]}>
								{stage === 2 ? (
									<GlassButton onClick={onPay} disabled={buttonState()}>
										<img src="/lock.svg" alt="lock"  /> Pay
										Securely Now
									</GlassButton>
								) : (
									<GlassButton onClick={nextStage} disabled={buttonState()}>
										Next
									</GlassButton>
								)}
							</div>
						</footer>
					) : (
						<div
							style={{
								textAlign: "center",
								padding: "4rem",
								display: "flex",
								gap: "1rem",
								flexWrap: "wrap",
								justifyContent: "center",
							}}>
							<VisaIcon />
							<AstaIcon />
							<TruspilotIcon />
							<PataIcon />
							<TruspilotIcon2 />
							<BbbIcon />
							<DiscoverIcon />
							<ActaIcon />
							<AmericanExpressIcon />
							<MasterCardIcon />
							<NortonIcon />
							<DinersClubIcon />
						</div>
					)}
				</>
			)}
		</section>
	);
}
