const SvgAmericanExpress = (props) => (
  <svg
    width="6rem"
    height="6rem"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M29.684 34.178h-2.196v1.717h2.186a.846.846 0 0 0 .977-.896c-.002-.35-.235-.82-.968-.82Zm7.552 0h-2.194v1.62h2.186c.719 0 .972-.32.972-.8 0-.35-.23-.82-.964-.82Zm-22.27-.01v1.34h3.871v1.356h-3.872v1.41h4.047l1.93-2.152-1.708-1.946-4.269-.009v.001ZM25.718 39.1v-5.777l-2.52 2.792 2.521 2.985Z"
      fill="#696363"
    />
    <path
      d="M57.767 39.807H54.08v-1.53h2.871c.49 0 1.213 0 1.213-.687 0-.327-.126-.533-.645-.589-.403-.04-.807-.067-1.212-.082-1.948-.052-2.4-1.02-2.4-2.2a1.975 1.975 0 0 1 2.09-2.073h3.687v1.532h-2.697c-.613.006-1.276-.072-1.276.623 0 .44.327.525.742.566.145 0 1.1.05 1.23.05 1.824.089 2.234.879 2.317 1.816v-6.117h-4.121c-.474.02-.938.145-1.358.366v-.366h-5.244c-.472.004-.935.13-1.344.366v-.366h-8.046v.397a3.2 3.2 0 0 0-1.46-.397h-6.262v.35a3.25 3.25 0 0 0-1.285-.35h-7.028l-1.35 1.493-1.32-1.493h-9.136v10.11h9.008l1.35-1.538 1.263 1.54h5.554v-2.455h1.619c.459.013.914-.078 1.333-.267v2.723h4.509V38.49h.2c.213 0 .319.044.319.286v2.455h14.411c.454.006.9-.096 1.305-.3v.3h5.422a2.273 2.273 0 0 0 1.66-.612v-2.566a2.111 2.111 0 0 1-2.231 1.755l-.001-.001Zm-27.948-2.441h-2.36v2.426H24.09l-2.056-2.377-2.107 2.36h-6.693v-7.148h6.913l1.947 2.233 2.01-2.216h6.156a1.977 1.977 0 0 1 2.124 2.185c.004 2.505-1.66 2.537-2.566 2.537Zm10.073.959.064 1.466h-1.709l-.046-1.308c-.016-.607-.254-1.211-.86-1.211h-2.298v2.52h-1.755v-7.148h4.563a1.886 1.886 0 0 1 2.105 1.913 1.95 1.95 0 0 1-1.213 1.98c.832.093 1.149.638 1.149 1.787Zm6.705-4.158h-3.98v1.341h3.868v1.356h-3.868v1.41h3.98v1.5h-5.739v-7.148h5.737l.002 1.541Zm4.563 5.64h-3.684v-1.53h2.871c.487 0 1.214 0 1.214-.687 0-.327-.129-.533-.647-.589a19.467 19.467 0 0 0-1.212-.082c-1.955-.052-2.4-1.02-2.4-2.2a1.97 1.97 0 0 1 2.09-2.073h3.686v1.532h-2.697c-.614.006-1.277-.072-1.277.623 0 .44.33.525.744.566.141 0 1.1.05 1.228.05 2.155.11 2.337 1.188 2.337 2.336a2.088 2.088 0 0 1-2.253 2.055v-.001ZM30.35 21.83h-2.193v1.62h2.185c.718 0 .978-.32.978-.8a.843.843 0 0 0-.97-.82Zm12.725 2.48h2.144l-1.1-2.498-1.044 2.499Z"
      fill="#696363"
    />
    <path
      d="M52.41 18.858v1.46l-.86-1.46h-4.733v1.46l-.65-1.46H38.79a3.406 3.406 0 0 0-1.867.549v-.55h-4.182v.392a2.833 2.833 0 0 0-1.44-.391H14.7l-1.134 2.433-1.155-2.423H7.153v1.43l-.605-1.441H2.247L0 23.937v4.99h3.064l.74-1.653h1.184l.7 1.652h5.907v-.819l.398.82h3.162l.365-.764v.763h13.946V26.26h.255s.261-.024.261.318v2.353h6.958v-.718c.682.44 1.47.687 2.28.717h3.495l.761-1.8h1.133l.81 1.8h5.778v-1.752l1.091 1.757h4.646v-10.08l-4.525.003Zm-34.035 8.587h-1.753v-5.217l-2.4 5.218h-1.6l-2.345-5.306v5.305h-3.53l-.694-1.6h-3.5l-.684 1.6H.013l3.128-7.147h2.322l3.063 6.883v-6.883h2.756l2.146 4.794 2.153-4.794h2.794v7.148Zm6.982-5.608H21.38v1.343h3.87v1.355h-3.87v1.411h3.98v1.5h-5.735V20.3h5.735l-.003 1.538v-.002ZM33 25.977l.064 1.47h-1.7l-.05-1.31c-.014-.605-.255-1.212-.862-1.212h-2.296v2.521H26.4V20.3h4.565a1.883 1.883 0 0 1 2.105 1.913 1.949 1.949 0 0 1-1.213 1.979c.826.094 1.15.637 1.15 1.785H33Zm2.738 1.47H33.99V20.29h1.755l-.006 7.155Zm19.863 0h-2.386l-3.158-5.234v5.233h-3.466l-.695-1.595h-3.5l-.687 1.595h-2.311c-1.947 0-2.81-1.63-2.768-3.61.059-2.933 1.794-3.544 2.591-3.544h2.292v1.552h-1.083c-1.167 0-2.078.122-2.078 1.8v.517c.01 1.26.671 1.559 1.093 1.646.104.025 1.125.025 1.125.025l2.418-5.537h2.322l2.989 6.71v-6.713h2.594l2.959 4.948v-4.948h1.755l-.008 7.154h.002Z"
      fill="#696363"
    />
    <path d="M3.213 24.308h2.134l-1.092-2.5-1.042 2.5Z" fill="#696363" />
  </svg>
);

export default SvgAmericanExpress;
