const SvgFull2 = ({white=false,...props}) => (
  <svg
    width="9.3rem"
    height="2.3rem"
    viewBox="0 0 99 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter={white ? "" : "url(#Full2_svg__a)"}>
      <path
        d="m7.307 17.236.96 2.601a.249.249 0 0 0 .467 0l.96-2.601a7.67 7.67 0 0 1 4.542-4.543l2.601-.96a.249.249 0 0 0 0-.467l-2.601-.96a7.67 7.67 0 0 1-4.543-4.542l-.96-2.601a.249.249 0 0 0-.466 0l-.96 2.601a7.67 7.67 0 0 1-4.543 4.543l-2.601.96a.249.249 0 0 0 0 .466l2.601.96a7.67 7.67 0 0 1 4.543 4.543Z"
        fill={white ? "#fff" : "#BF9377"}
      />
    </g>
    <path
      d="M30.899.907H27v21.126h3.899v-8.04h10.85v-3.656h-10.85V.907Zm3.929 0-1.33 3.869h10.517V.907h-9.187ZM61.13 9.642C58.23 8.735 55.6 8.07 55.6 6.045c0-1.27 1.42-2.236 4.382-2.236 2.236 0 4.261.695 6.437 1.904l1.904-3.022c-2.78-1.632-5.289-2.358-8.16-2.358-2.75 0-4.986.574-6.498 1.693-1.39 1.027-2.115 2.448-2.115 4.14 0 4.292 4.11 5.591 7.767 6.71 4.261 1.3 8.583 3.143 8.916 6.376.544-.846.876-1.843.876-3.052 0-4.02-4.412-5.44-7.979-6.558Zm2.962 8.22c-.755.726-2.236 1.058-4.17 1.058-2.267 0-4.685-.725-6.83-1.934l-1.965 3.143c2.78 1.602 5.53 2.448 8.643 2.448 3.839 0 5.38-1.3 5.38-2.66 0-.876-.393-1.541-1.058-2.055ZM94.293 22.033h3.96L89.79.907h-5.107L76.22 22.033h3.959l1.904-4.745h7.434l-1.42-3.536h-4.594l3.718-9.339 7.072 17.62Z"
      fill={white ? "#fff" : "#252323"}
    />
    <defs>
      <filter
        id="Full2_svg__a"
        x={-1}
        y={2}
        width={19}
        height={19}
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity={0} result="BackgroundImageFix" />
        <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={-1} dy={1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.913626 0 0 0 0 0.609798 0 0 0 0 0.417671 0 0 0 0.58 0" />
        <feBlend in2="shape" result="effect1_innerShadow_781_198706" />
        <feColorMatrix
          in="SourceAlpha"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx={1} dy={-1} />
        <feGaussianBlur stdDeviation={0.5} />
        <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
        <feColorMatrix values="0 0 0 0 0.737957 0 0 0 0 0.472523 0 0 0 0 0.301886 0 0 0 0.4 0" />
        <feBlend
          in2="effect1_innerShadow_781_198706"
          result="effect2_innerShadow_781_198706"
        />
      </filter>
    </defs>
  </svg>
);

export default SvgFull2;
