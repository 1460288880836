import s from './index.module.sass'

export default function Nav({ stage, texts }) {
  return (
    <nav className={s.nav}>
      <ul className={s['nav__items']}>

        {texts.map((item,index)=>{

          let classes = [s['nav-item']]
          if (stage === index) classes.push(s['nav-item--active'])
          if (stage > index) classes.push(s['nav-item--white'])

          return (
            <li key={index} className={classes.join(' ')}>
              {stage > index && <img src="/boxes.svg" alt="check" loading={'lazy'} />}
              {item}
            </li>
          ) 
        })}

      </ul>
    </nav>
  )
}