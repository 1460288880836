const SvgBbb = (props) => (
  <svg
    width="6rem"
    height="6rem"
    viewBox="0 0 60 61"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m14.025 32.793 2.043 1.475c.35.253.377.504.213.77l.297.222 1.034-1.433a1.937 1.937 0 0 0-.365-2.78l-2.06-1.5a.517.517 0 0 1-.135-.712l-.299-.214-1.101 1.518a2.054 2.054 0 0 0 .373 2.654Zm1.368-4.686 2.818 2.07a1.592 1.592 0 0 1 .23 2.035l.239.18 1.944-2.737a2.88 2.88 0 0 0-.548-4.04l-3.015-2.191a.916.916 0 0 1-.283-1.323l-.26-.184-1.714 2.372a2.943 2.943 0 0 0 .59 3.818ZM12.3 35.779 12 36.793h2.274l.405 1.378h3.714l.404-1.378h2.274l-.3-1.014H12.3Zm18.805-1.99c-.174-1.144-.651-1.418-1.605-1.825a2.042 2.042 0 0 0 1.164-1.83c0-1.643-1.393-2.312-2.913-2.312h-4.247v8.723h4.206c1.797 0 3.701-.76 3.396-2.757Zm-5.68-4.298h2.206c1.378 0 1.428 1.862.085 1.862h-2.293l.001-1.862Zm2.46 5.407h-2.46v-1.991h2.46c1.81 0 1.7 1.99 0 1.99v.001Zm11.651-1.11c-.174-1.143-.652-1.417-1.605-1.823a2.043 2.043 0 0 0 1.164-1.83c0-1.644-1.393-2.313-2.914-2.313h-4.246v8.723h4.207c1.796 0 3.7-.76 3.395-2.757Zm-5.68-4.297h2.206c1.378 0 1.428 1.862.086 1.862h-2.294l.002-1.862Zm2.46 5.407h-2.46v-1.991h2.46c1.81 0 1.7 1.99 0 1.99v.001Zm11.652-1.11c-.175-1.143-.652-1.417-1.606-1.823a2.041 2.041 0 0 0 1.165-1.83c0-1.644-1.394-2.313-2.914-2.313h-4.247v8.723h4.208c1.794 0 3.7-.76 3.395-2.757h-.001Zm-5.68-4.297h2.206c1.378 0 1.428 1.862.085 1.862h-2.293l.002-1.862Zm2.46 5.407h-2.46v-1.991h2.458c1.812 0 1.701 1.99 0 1.99l.002.001Z"
      fill="#696363"
    />
  </svg>
);

export default SvgBbb;
