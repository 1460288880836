import s from './index.module.sass'

// redux
import { useSelector} from 'react-redux'

// icons
import Call2Icon from '../Icons/icon/Call2'

export default function AgentCard() {
  
  const AGENT = useSelector((state) => state.agent);

  return (
    <div className={s.agent}>
      <div className={s['agent__info']}>
        <span className={s['agent__info-name']}>{AGENT.info.firstName} {AGENT.info.lastName}</span>
        <span className={s['agent__info-phone']}><Call2Icon width="1.4rem" height="1.4rem" color="var(--text100)" /> <a href={`tel:${'+1' + AGENT.info.phone}`}>{AGENT.info.phone}</a></span>
      </div>
      <div className={s['agent__avatar']}>
        <div className={s['agent__avatar-status']}></div>
        <img className={s['agent__avatar-img']} src="/agent/agent.png" alt="agent"  />
      </div>
    </div>
  )
}